.footer {
  color: white;
  padding: 1.6rem 0;
  font-weight: bold;
  text-align: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--main-color);
  background-image: url("../../images/iconos-pagina/Footer/background.png");
}

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main > figure {
  margin: 0;
}

.containerText {
  width: 70vw;
  margin: auto;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-family: var(--FONT-BOLD);
}

.mainNetwork {
  display: flex;
  align-items: center;
}

.mainNetwork > p {
  color: white;
  font-size: 1.2rem;
}

.icons {
  width: 50px;
}

.icons > img {
  width: 100%;
}

.networks {
  display: flex;
  justify-content: center;
}

.networks > a > figure {
  margin: 0;
}

.reactIcons {
  color: white;
  margin: 0 1rem;
  font-size: 3rem;
}

@media (max-width: 992px) {
  .containerText {
    width: 80vw;
  }

  .icons {
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  .contact {
    display: block;
  }

  .contact > div {
    display: inline-block;
  }

  .copyright {
    margin-top: 3rem;
  }
}

@media (max-width: 500px) {
  .containerText {
    width: 90vw;
  }

  .icons {
    margin-left: 0px;
    margin-right: 10px;
  }
}
