.figure {
  width: 15%;
  margin: auto;
  text-align: center;
}

.figure > img {
  width: 100%;
}

.container {
  width: 85%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  vertical-align: baseline;
  justify-content: space-between;
  font-family: var(--FONT_BOLD);
}

.mission,
.vision {
  width: 45%;
  text-align: center;
  box-sizing: border-box;
}

.mission h2,
.vision h2 {
  color: black;
  font-size: 25px;
  margin-bottom: 3rem;
  font-family: var(--FONT-MEDIUM);
}

.mission p,
.vision p {
  color: black;
  font-size: 15px;
  font-family: var(--FONT-REGULAR);
}

.mission img,
.vision img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

@media screen and (max-width: 850px) {
  .figure {
    width: 25%;
  }
}

@media screen and (max-width: 600px) {
  .figure {
    width: 50%;
  }

  .container {
    width: 90%;
    display: block;
  }

  .mission,
  .vision {
    width: 100%;
    margin: 1rem 0;
  }
}
