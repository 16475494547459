.banner_section {
  width: 100%;
  height: 600px;
  position: relative;
}

.foto {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contenedor_texto {
  right: 0;
  width: 50%;
  left: 30px;
  bottom: 50%;
  color: #000;
  position: absolute;
  text-align: center;
  background: transparent;
  transform: translate(0, 50%);
}

.contenedor_texto > h3 {
  line-height: 1.5;
  margin-bottom: 3rem;
  background: transparent;
  font-family: var(--FONT-BOLD);
}

.boton_credito2 {
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.3rem;
  border-radius: 50px;
  padding: 0.5rem 2rem;
  font-family: var(--FONT-BOLD);
  background-color: var(--main-color);
}

@media only screen and (max-width: 768px) {
  .banner_section {
    height: 500px;
  }

  .contenedor_texto {
    left: 30px;
    right: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .contenedor_texto {
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .contenedor_texto > h2 {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 450px) {
  .banner_section {
    height: 300px;
  }

  .contenedor_texto > h2 {
    font-size: 1.1rem;
  }
}
