.main {
  margin-top: 3rem;
}

.title {
  display: flex;
  font-size: 25px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.title > p {
  margin: 0;
  font-family: var(--FONT-BOLD);
}

.figureImage {
  margin: 0;
  width: 200px;
}

.figureImage > img {
  width: 100%;
}

.container_preguntas_frecuentes {
  width: 90vw;
  display: flex;
  margin: 0 auto;
  font-size: 17px;
  align-items: center;
  font-family: var(--FONT-BOLD);
  justify-content: space-between;
}

.textQuestion {
  width: 48%;
}

.textQuestion > dt {
  font-family: var(--FONT-MEDIUM);
}

.textQuestion > dd {
  margin-left: 15px;
  text-align: justify;
  line-height: 1.3;
  margin-bottom: 1.5rem;
  font-family: var(--FONT-REGULAR);
}

.imageQuestions {
  margin: 0;
  width: 48%;
}

.imageQuestions > img {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .container_preguntas_frecuentes {
    display: block;
  }

  .textQuestion {
    width: 100%;
  }

  .imageQuestions {
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .main {
    margin-top: 4rem;
  }

  .container_preguntas_frecuentes {
    width: 90vw;
    margin: 2rem auto;
  }

  .imageQuestions {
    width: 60%;
  }
}

@media screen and (max-width: 500px) {
  .imageQuestions {
    width: 100%;
  }
}
