.solicitar {
  font-size: 25px;
  margin-top: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  font-family: var(--FONT-BOLD);
}

.services_container {
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  font-family: var(--FONT-LIGHT);
}

.service {
  width: 100%;
  padding: 0 10px;
  text-align: center;
  box-sizing: border-box;
}

.service h4 {
  font-size: 1rem;
  margin-bottom: 20px;
}

.image {
  height: 102px;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .solicitar {
    padding: 0 10px;
    margin-top: 2rem;
    font-size: 1.2rem;
  }

  .services_container {
    display: block;
  }

  .service {
    width: 100%;
    margin: 2rem 0;
    text-align: center;
  }

  .image {
    height: 130px;
  }
}
