.quienes_somos {
  margin: 2rem 0;
  padding: 1rem 0;
  background-color: var(--DARK-GRAY);
}

.container {
  width: 90vw;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aboutUsDefinition {
  width: 45%;
}

.aboutUsDefinition > h2 {
  text-align: center;
  margin-bottom: 3rem;
  font-family: var(--FONT-BOLD);
}

.aboutUsDefinition > p {
  line-height: 1.5;
  text-align: justify;
  font-family: var(--FONT-REGULAR);
}

.imagen {
  width: 25%;
}

.imagen > img {
  width: 100%;
}

@media screen and (max-width: 850px) {
  .quienes_somos {
    margin: 4rem 0;
  }

  .container {
    width: 90vw;
    display: block;
  }

  .aboutUsDefinition {
    width: 100%;
  }

  .aboutUsDefinition > h2 {
    text-align: center;
  }

  .imagen {
    width: 60%;
    margin: auto;
  }
}
