header {
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 10px;
  position: fixed;
  align-items: center;
  z-index: 9999999999999;
  background: var(--LIGHT-GRAY);
  justify-content: space-between;
}

.logo {
  margin: 0;
}

.logo_preztum {
  width: 250px;
}

.nav {
  width: 40%;
}

ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  list-style-type: none;
  justify-content: space-between;
}

.boton_credito {
  height: 2rem;
  width: 182px;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  border-radius: 50px;
  font-family: var(--FONT-BOLD);
  background-color: var(--main-color);
}

a {
  color: #333;
  text-decoration: none;
  font-family: var(--FONT-SEMIBOLD);
}

.menuHamburguer {
  display: none;
}

@media (max-width: 992px) {
  header {
    display: block;
    text-align: center;
  }

  .containerButton,
  .nav {
    display: none;
  }

  .menuHamburguer {
    top: 50%;
    display: block;
    font-size: 2.5rem;
    position: absolute;
    /* color: var(--DARK-GRAY); */
    transform: translateY(-50%);
  }

  nav > ul {
    display: block;
  }

  li {
    margin: 10px 0;
  }
}
