body {
  margin: 0;
  padding-top: 70.08px;
  box-sizing: border-box;
  background: var(--LIGHT-GRAY);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Quicksand-Bold";
  src: local("Quicksand-Bold"),
    url(./fonts/Quicksand/static/Quicksand-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Quicksand-Medium";
  src: local("Quicksand-Medium"),
    url(./fonts/Quicksand/static/Quicksand-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Quicksand-Light";
  src: local("Quicksand-Light"),
    url(./fonts/Quicksand/static/Quicksand-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Quicksand-Regular";
  src: local("Quicksand-Regular"),
    url(./fonts/Quicksand/static/Quicksand-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Quicksand-SemiBold";
  src: local("Quicksand-SemiBold"),
    url(./fonts/Quicksand/static/Quicksand-SemiBold.ttf) format("truetype");
}

:root {
  --FONT-BOLD: "Quicksand-Bold";
  --FONT-LIGHT: "Quicksand-Light";
  --FONT-MEDIUM: "Quicksand-Medium";
  --FONT-REGULAR: "Quicksand-Regular";
  --FONT-SEMIBOLD: "Quicksand-SemiBold";

  --DARK-GRAY: #a6a6a6;
  --LIGHT-GRAY: #e3e3e3;
  --main-color: #015c83;
}

* {
  box-sizing: border-box;
  font-family: var(--FONT-LIGHT);
}
