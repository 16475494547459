.sectionAllies {
  margin: 4rem 0;
  padding-bottom: 1rem;
  background: var(--DARK-GRAY);
}

.container_aliados {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container_aliados > h2 {
  font-family: var(--FONT-BOLD);
}

.marcas {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.marca {
  width: 25%;
  text-align: center;
}

.marca img {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .sectionAllies {
    margin: 4rem 0;
  }

  .marca img {
    width: 80%;
  }
}
