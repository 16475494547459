.navbar {
  left: 0;
  right: 0;
  bottom: 0;
  top: 70.08px;
  position: fixed;
  padding: 1.3rem;
  background: var(--LIGHT-GRAY);
}

.containerItems {
  text-align: left;
  margin-bottom: 4rem;
}

.containerItems > li > a {
  font-size: 1.5rem;
}

.boton_credito {
  width: 100%;
  border: none;
  padding: 10px;
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  border-radius: 50px;
  font-family: var(--FONT-BOLD);
  background-color: var(--main-color);
}
